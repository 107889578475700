<template>
  <div>
    <van-nav-bar
      title="查看种植档案的信息"
      left-text="返回"
      left-arrow
      @click-left="onClickBank"
    />
    <div id="container"></div>
    <div class="content">{{this.position}}-{{this.name}}</div>
  </div>
</template>

<script>
import AMap from "AMap";
import { NavBar } from "vant";
export default {
    name: "ArchivesMap",
  data() {
    return {
        name: "",
        position: "",
        latitude: "",
        longitude: ""
    };
  },
  components: {
    VanNavBar: NavBar
  },
  methods: {
    onClickBank() {
      this.$router.go(-1);
    },
    init() {
      this.latitude = this.$route.params.latitude;
      this.longitude = this.$route.params.longitude;
      this.name = this.$route.params.name;
      this.position = this.$route.params.position;
      var that = this;
      that.map = new AMap.Map("container", {
        zoom: 12,
        center: [this.longitude, this.latitude]
      });
      var marker = new AMap.Marker({
        position: new AMap.LngLat(this.longitude, this.latitude),
        title: "这里是您选中的地块"
      });

      // 将创建的点标记添加到已有的地图实例：
      that.map.add(marker);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
html,
body {
  width: 100vw;
  height: 100vh;
}
#container {
  width: 100vw;
  height: 93vh;
  transform: translate(0,0);
}
.content{
    position: fixed;
    bottom: 5vh;
    width: 100vw;
    height: 5vh;
    background: #fff;
    text-align: center;
    font-size: 3vh;
}
</style>